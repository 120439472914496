<template>
  <div>
    <v-toolbar>
      <v-toolbar-title> Supervisão - Unidades </v-toolbar-title>
    </v-toolbar>

    <v-container style="max-width: 900px">
      <v-row>
        <v-col class="text-right">
          <v-spacer></v-spacer>
          <v-btn-toggle v-model="time_scope" color="primary">
            <v-btn value="1 year"> 1 ano </v-btn>

            <v-btn value="1 month"> 30 dias </v-btn>

            <v-btn value="1 day"> 1 dia </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col>
                  <div class="">DISTRIBUÍDO AUTOMATICAMENTE</div>
                  <div class="text-h4 mt-2">
                    {{ total_distributed_automatically.length }}
                  </div>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col>
                  <div class="">DISTRIBUÍDO MANUALMENTE</div>
                  <div class="text-h4 mt-2">
                    {{ total_distributed_manually.length }}
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-card>
            <v-card-title> Total distribuído por unidade </v-card-title>

            <v-card-text>
              <horizontal-bar-chart
                :chart-data="chartdata"
                :options="bars_options"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HorizontalBar from "@/components/charts/HorizontalBar";
import { get_totals } from "@/services/supervision/lead_distribution_for_unities";

export default {
  components: {
    "horizontal-bar-chart": HorizontalBar,
  },
  data() {
    return {
      time_scope: "1 day",
      total_distributed_automatically: [],
      total_distributed_manually: [],
      total_distributed_by_unity: [],
      chartdata: {
        labels: [],
        datasets: [],
      },
      bars_options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: "bottom",
          align: "start",
          fullWidth: false,
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              beginAtZero: true,
            },
          ],
          yAxes: [
            {
              stacked: true,
            },
          ],
        },
      },
    };
  },

  methods: {
    async loadData() {
      this.chartdata = {
        ...this.chartdata,
        datasets: [],
      };
      const {
        total_distributed_automatically,
        total_distributed_manually,
        total_distributed_by_unity,
      } = await get_totals(this.time_scope);
      this.total_distributed_automatically = total_distributed_automatically;
      this.total_distributed_manually = total_distributed_manually;
      this.total_distributed_by_unity = total_distributed_by_unity;
      const labels = total_distributed_by_unity.map((item) => {
        return item.unity.name;
      });
      const datasets = [
        {
          label: "Atribuições",
          backgroundColor: "rgb(41, 87, 123, 1)",
          minBarLength: 0,
          data: total_distributed_by_unity.map((item) => {
            return item.leads.length;
          }),
          xAxisID: 0,
          stepSize: 1,
        },
      ];
      this.chartdata = {
        labels,
        datasets: datasets,
      };
    },
  },

  watch: {
    time_scope(value) {
      this.loadData();
    },
  },

  created() {
    this.loadData();
  },
};
</script>
