import Vue from "vue";

export async function get_totals(scope = "1 day") {
  const response = await Vue.prototype.$http.get(
    `/analytics/leads_distribution_for_unities`,
    {
      params: {
        scope,
      },
    }
  );

  return response.data;
}
